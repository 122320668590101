import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";

const IndexPageTemplate = ({ content }) => (
  <div className="profile">
    <section className="hero-section">
      <div style={{ display: "grid" }}>
        {/* You can use a GatsbyImage component if the image is dynamic */}
        <StaticImage
          style={{
            gridArea: "1/1",
            // You can set a maximum height for the image, if you wish.
            height: "calc(100vh - 64px)",
          }}
          layout="fullWidth"
          loading="eager"
          // You can optionally force an aspect ratio for the generated image
          // aspectRatio={3 / 1}
          // This is a presentational image, so the alt should be an empty string
          alt="hero background image"
          // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
          src={"../img/background-hero-image.png"}
          formats={["auto", "webp", "avif"]}
        />
        <div
          style={{
            // By using the same grid area for both, they are stacked on top of each other
            gridArea: "1/1",
            position: "relative",
            // This centers the other elements inside the hero component
            display: "grid",
            placeItems: "center",
          }}
        >
          {/* Any content here will be centered in the component */}
          <div className="profile-intro slide-up">
            {/* <div className="profile-photo " style={{ margin: ".5rem" }}>
              <StaticImage
                style={{
                  height: 75,
                  width: 75,
                  borderRadius: "50%",
                }}
                alt="profile picture"
                // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
                src={"../img/profile.jpg"}
                formats={["auto", "webp", "avif"]}
              />
            </div> */}
            <p className="is-size-1 is-size-3-mobile is-size-1-tablet has-text-centered">
              Hey, I'm <span className="primary">Nida</span>
            </p>
            <p className="is-size-4 is-size-5-mobile is-size-4-tablet has-text-centered">
              <span className="black">{content.subheading}</span>
            </p>
            {/* <p className="is-size-6-tablet is-size-7-mobile has-text-centered">
              I'm a full-time Full Stack Developer from India, having 3+ years
              of experience in developing web, mobile, desktop applications. I'm
              also, a freelance
            </p> */}

            <Link to="/about/#contact" className="button is-primary contact-me">
              Contact Me
            </Link>
            <p className="is-size-6 is-size-7-mobile has-text-centered">
              Follow me
              <p className="social follow-me">
                <a
                  title="GitHub"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="github"
                  href="https://github.com/itsZed0"
                >
                  {/* <StaticImage
                    src="../img/social/facebook.svg"
                    alt="Facebook"
                    style={{ width: "1em", height: "1em" }}
                  /> */}
                  {/* <i className="fab fa-github"></i> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="grey"
                  >
                    <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
                  </svg>
                </a>
                <a
                  title="linkedin"
                  target="_blank"
                  aria-label="linkedin"
                  rel="noreferrer"
                  href="https://www.linkedin.com/in/kakbar"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="grey"
                  >
                    <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                  </svg>
                </a>
              </p>
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="skills-section has-text-centered">
      {/* <Search indices={searchIndices} /> */}
      <h1 className="is-size-3 is-size-5-mobile is-size-3-tablet has-text-centered">
        Skills
      </h1>
      <p className="is-size-4 is-size-7-mobile is-size-4-tablet has-text-centered">
        <p>
          {content.skills} <a href="/blog/">blog</a>.
        </p>
        <br></br>
        <p>My skills include:</p>
      </p>
      <div className="flex flex-row" style={{ margin: "2rem 0", gap: "2rem" }}>
        <div>
          <StaticImage
            src="../img/skills/java.svg"
            alt="Java"
            style={{ width: "5rem", height: "5rem" }}
          />
          <p>Java</p>
        </div>
        <div>
          <StaticImage
            src="../img/skills/spring-boot.svg"
            alt="Spring Boot"
            style={{ width: "5rem", height: "5rem" }}
          />
          <p>Spring Boot</p>
        </div>
        <div>
          <StaticImage
            src="../img/skills/react.svg"
            alt="React"
            style={{ width: "5rem", height: "5rem" }}
          />
          <p>React</p>
        </div>
        <div>
          <StaticImage
            src="../img/skills/redux.svg"
            alt="Redux"
            style={{ width: "5rem", height: "5rem" }}
          />
          <p>Redux</p>
        </div>
        <div>
          <StaticImage
            src="../img/skills/docker.svg"
            alt="Docker"
            style={{ width: "5rem", height: "5rem" }}
          />
          <p>Docker</p>
        </div>
        <div>
          <StaticImage
            src="../img/skills/mui.svg"
            alt="MUI"
            style={{ width: "5rem", height: "5rem" }}
          />
          <p>Material UI</p>
        </div>
        <div>
          <StaticImage
            src="../img/skills/gatsby.svg"
            alt="gatsby"
            style={{ width: "5rem", height: "5rem" }}
          />
          <p>Gatsby</p>
        </div>

        <div>
          <StaticImage
            src="../img/skills/flutter.svg"
            alt="Flutter"
            style={{ width: "5rem", height: "5rem" }}
          />
          <p>Flutter</p>
        </div>
        <div>
          <StaticImage
            src="../img/skills/aws.svg"
            alt="AWS"
            style={{ width: "5rem", height: "5rem" }}
          />
          <p>AWS</p>
        </div>
      </div>
    </section>
    <section className="tech-writing-section has-text-centered ">
      <h1 className="is-size-3 is-size-5-mobile is-size-3-tablet has-text-centered">
        Technical Content Writing
      </h1>
      <p className="is-size-4 is-size-7-mobile is-size-4-tablet has-text-centered">
        <p>
          I've written technical articles for sites like Twilio, Sitepoint. If
          you want me to write articles for your website, you can reach out at
          <a aria-label="gmail" href="mailto:nida10030@gmail.com">
            {" "}
            nida10030@gmail.com{" "}
          </a>
          or message me on
          <a
            target="_blank"
            rel="noreferrer"
            aria-label="linkedin"
            href="https://www.linkedin.com/in/kakbar"
          >
            {" "}
            LinkedIn
          </a>
          .
        </p>
        <br></br>
        <p>Find some of my guest writings below:</p>
        <div style={{ margin: "2rem 0" }} className="grid grid-3 blog-grid">
          <a
            target="_blank"
            rel="noreferrer"
            aria-label="github profile readme blog"
            href="https://www.sitepoint.com/github-profile-readme/"
          >
            <StaticImage
              className="is-12"
              src="../img/blogs/sitepoint-github-readme.PNG"
              alt="Memoization in React"
              style={{
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
            />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            aria-label="spring boot blog"
            href="https://www.twilio.com/blog/create-rest-apis-java-spring-boot"
          >
            <StaticImage
              className="is-12"
              src="../img/blogs/twilio-spring-boot.png"
              alt="Spring Boot For Beginners"
              style={{
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
            />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            aria-label="memoization react blog"
            href="https://www.sitepoint.com/implement-memoization-in-react-to-improve-performance/"
          >
            <StaticImage
              className="is-12"
              src="../img/blogs/sitepoint-react.png"
              alt="Memoization in React"
              style={{
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
              // style={{ width: "5rem", height: "5rem" }}
            />
          </a>
        </div>
      </p>
    </section>
  </div>
);

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <SEO />
      <IndexPageTemplate content={frontmatter} />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        heading
        subheading
        skills
      }
    }
  }
`;
